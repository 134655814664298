::-webkit-scrollbar {
    display: none;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

#table-input {
    margin-left: 10px;
    text-align: center;
    border: none;
    border-bottom: 1px solid grey;
    width: 50px;
    outline: none;
}

input#table-input::-webkit-outer-spin-button,
input#table-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input#table-input {
    -moz-appearance: textfield;
}
#qr_caption {
    border: 2px solid #ced1d5 !important;
    padding: 5px 8px;
    border-radius: 10px;
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 0;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: auto;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding-top: 0;
    resize: none;
    padding: 5px 10px;
}

textarea#qr_caption:hover{
    border: #35d1f5 solid 2px !important;
}
textarea#qr_caption:focus{
    outline: none !important;
    border: #35d1f5 solid 2px !important;
}