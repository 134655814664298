.circle-container {
    display: block;
    position: relative;
    width: calc(
        var(--wheel-size) + 2 * var(--circle-ring-size) + 2 *
            var(--inner-ring-size)
    );
    height: calc(
        var(--wheel-size) + 2 * var(--circle-ring-size) + 2 *
            var(--inner-ring-size)
    );
    border-radius: 50%;
    background: var(--ring-color);
    transition: transform var(--spin-duration) cubic-bezier(0.44, -0.205, 0, 1);
    transform: rotate(calc(1deg * var(--rotation)));
    transform: rotate(270deg);
}

.circle {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 20px;
    transform-origin: center left;
    transform: translateY(-50%)
        rotate(calc(var(--circle-index) * (360deg / var(--circle-count))));
    color: var(--item-color);
    text-align: right;
    padding: 0 25px 0 50px;
}

.circle::before {
    content: "";
    display: block;
    position: absolute;
    right: 5px;
    width: 20px;
    height: 20px;
    background-color: var(--circle-color);
    border-radius: 50%;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(
        var(--wheel-size) + 2 * var(--circle-ring-size) + 2 *
            var(--inner-ring-size)
    );
    height: calc(
        var(--wheel-size) + 2 * var(--circle-ring-size) + 2 *
            var(--inner-ring-size)
    );
    border-radius: 50%;
}
/* arrow */
/* .circle-arrow {
    display: block;
    position: absolute;
    width: 70px;
    height: 70px;
    top: 50%;
    right: -15px;
    transform: translateY(-50%) rotate(90deg);
    z-index: 10;
} */
.circle-arrow{
    position: absolute;
    width: 150px;
    height: 60px;
    top: 50%;
    right: -45px;
    transform: translateY(-50%) rotate(90deg);
    z-index: 10;
}